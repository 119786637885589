import {createContext, Dispatch} from 'react'
import {Action, AppState} from '../types'

export const initialState: AppState = {
    alerts: [],
    loading: false,
    options: {
        apiKey: '',
        inbounds: [],
        inboundSmsTrigger: false,
        performanceTracking: false,
        responsible: null,
    },
}

export default createContext<{
    dispatch: Dispatch<Action>
    state: AppState
}>({
    dispatch: () => null,
    state: initialState,
})