import React from 'react'
import {useTranslation} from 'react-i18next'
import Checkbox from '../Inputs/Checkbox'

export const InboundSmsTrigger: React.FC = () => {
    const {t} = useTranslation()

    return <Checkbox
        id='inboundSmsTrigger'
        label={t('inboundSmsTrigger')}
    />
}
