(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
    typeof define === 'function' && define.amd ? define(factory) :
    (global = typeof globalThis !== 'undefined' ? globalThis : global || self, global.Sms77SharedInboundNumbers = factory());
}(this, (function () { 'use strict';

    var Germany = {
        Eplus: '+491771783130',
        O2: '+4917626702044',
        Vodafone: '+491625453093',
        Telekom: '+4915126716517'
    };
    var Switzerland = {
        Swisscom: '+41798072355'
    };
    var Poland = {
        Play: '+48732484001'
    };
    var UnitedKingdom = {
        Hutchinson: '+447449241777'
    };
    var UnitedStates = {
        Local: '+13134378004'
    };
    var index = {
        Germany: Germany,
        Switzerland: Switzerland,
        Poland: Poland,
        UnitedKingdom: UnitedKingdom,
        UnitedStates: UnitedStates
    };

    return index;

})));
