import {Dispatch} from 'react'
import {TFunction} from 'react-i18next'
import {Endpoint} from '../../shared'
import {AnyObject, Options} from '../../types'
import {AddAlertAction} from '../types'
import {requestAPI} from './requestAPI'

export const postSettings = async ({
                                       t,
                                       ...params
                                   }: {
    data: AnyObject
    dispatch: Dispatch<AddAlertAction>
    t: TFunction
}): Promise<Options | undefined> => {
    const res = await requestAPI<Options>({
        ...params,
        endpoint: Endpoint.Settings,
        method: 'POST',
    })

    if (!('error' in (res || {}))) params.dispatch({
        alert: 'info',
        text: t('settingsUpdated'),
        type: 'ADD_ALERT',
    })

    return res
}
