import React, {useReducer} from 'react'
import AppContext, {initialState} from '../lib/AppContext'
import {Action, AppState} from '../types'

export const mainReducer = (s: AppState, a: Action): AppState => {
    switch (a.type) {
        case 'ADD_ALERT': {
            return {
                ...s,
                alerts: [
                    ...s.alerts,
                    {alert: a.alert, text: a.text}
                ]
            }
        }

        case 'REMOVE_ALERT': {
            s.alerts.splice(a.index, 1)

            return {...s, alerts: s.alerts}
        }

        case 'SET_LOADING': {
            return {...s, loading: a.loading}
        }

        case 'SET_OPTION': {
            return {...s, options: {...s.options, [a.key]: a.value}}
        }

        case 'SET_OPTIONS': {
            return {...s, options: {...s.options, ...a.options}}
        }

        default:
            throw new Error(`Unhandled Action ${a}`)
    }
}

type AppContextProviderProps = { children: React.ReactElement }

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
                                                                          children
                                                                      }: AppContextProviderProps) => {
    const [state, dispatch] = useReducer(mainReducer, initialState)

    return <AppContext.Provider value={{dispatch, state}}>
        {children}
    </AppContext.Provider>
}
