import constants from './server/lib/constants'
import sharedInbounds from '@sms77.io/shared-inbound-numbers'

export enum Endpoint {
    Index = '/index',
    Install = '/install',
    ReinitializeSms = '/reinitialize-sms',
    Settings = '/settings',
    Sms = '/sms',
    Webhook = '/webhook',
}

export const messageServiceSender = {
    CODE: 'sms77',
    DESCRIPTION: 'Send SMS via the Sms77.io SMS Gateway.',
    HANDLER: buildEndpointURL(Endpoint.Sms),
    NAME: 'sms77.io SMS Gateway',
    TYPE: 'SMS',
}

const sharedNumbers = [
    ...Object.values(sharedInbounds.Germany),
    ...Object.values(sharedInbounds.Switzerland),
    ...Object.values(sharedInbounds.Poland),
    ...Object.values(sharedInbounds.UnitedKingdom),
    ...Object.values(sharedInbounds.UnitedStates),
]

export function cleanNumber(nr: string): string {
    nr = nr.replace(/[^0-9]/gm, '')

    nr = nr.replace(/^0*/gm, '')

    return nr
}

export function isSharedNumber(nr: string): boolean {
    nr = cleanNumber(nr)

    for (const shared of sharedNumbers) if (nr === shared) return true

    for (const shared of sharedNumbers) if (nr === `00${shared}`) return true

    return false
}

export function ensureTrailingSlash(str: string): string {
    return str.endsWith('/') ? str : `${str}/`
}

export function stripTrailingSlash(str: string): string {
    return str.startsWith('/') ? str.substring(1) : str
}

export function buildEndpointURL(endpoint: Endpoint) {
    return ensureTrailingSlash(constants.host) + stripTrailingSlash(endpoint.toString())
}
