{
    "translation": {
        "apiKey": "API-Schlüssel",
        "adminsOnly": "Es werden administrative Rechte benötigt um Einstellungen vornehmen zu können.",
        "country": "Land",
        "delete": "Löschen 🗑",
        "inboundNumbers": {
            "add": "Inbound-Nummer hinzufügen",
            "deleteAll": "Ausgewählte Inbound-Nummer(n) löschen",
            "intro": "Nach hinzufügen von Inbound-Nummern werden eingehende SMS auf diese Nummern dem Aktivitätsverlauf hinzugefügt. Wird kein Kontakt mit der eingehenden Rufnummer gefunden, wird ein neuer Lead erzeugt.",
            "title": "Inbound-Nummern",
            "toggleAll": "Alle Inbound-Nummern auswählen"
        },
        "inboundSmsTrigger": "Trigger hinzufügen für eingehende SMS?",
        "loading": "Lädt...",
        "numberExists": "Diese Rufnummer existiert bereits.",
        "performanceTracking": "Performance Tracking",
        "reinitializedSms": "Neu initialisierte SMS - bitte erneut versenden.",
        "reinitializeSms": "SMS neu initialisieren",
        "reinitializeSmsDescription": "Bei fehlerhaftem SMS-Versand bitte den SMS-Nachrichtendienst neu initialisieren.",
        "responsible": "Verantwortlich",
        "responsibleDesc": "Arbeitskraft für Zuweisung von eingehenden Nachrichten.",
        "saveOptions": "Einstellungen speichern",
        "selectOption": "Einstellung auswählen",
        "set": "Festlegen",
        "settingsUpdated": "Die Einstellungen wurden aktualisiert!",
        "sharedNumbersUnsupported": "Geteilte Rufnummern werden derzeit nicht unterstützt.",
        "toggleAll": "Alle auswählen"
    }
}
