import {Dispatch} from 'react'
import {buildEndpointURL, Endpoint} from '../../shared'
import {AnyObject} from '../../types'
import {AddAlertAction} from '../types'

export async function requestAPI<T extends AnyObject>({
                                                          data = {},
                                                          dispatch,
                                                          endpoint,
                                                          method,
                                                      }: {
    endpoint: Endpoint
    data?: { [k: string]: any }
    dispatch: Dispatch<AddAlertAction>
    method: 'GET' | 'POST'
})
    : Promise<T | undefined> {
    let response: T | undefined = undefined
    let url = buildEndpointURL(endpoint)

    const auth = window.BX24.getAuth()
    const req: RequestInit = {
        headers: {
            'Content-Type': 'application/json',
        },
        method,
    }

    if ('GET' === method) {
        const {member_id} = auth
        data.member_id = member_id
        const params = Object.keys(data)
            .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`)
            .join('&')

        url += `?${params}`
    } else {
        data.auth = auth
        req.body = JSON.stringify(data)
    }

    try {
        const json = await (await fetch(url, req)).json()

        if (json.error) dispatch({
            alert: 'error',
            text: json.error,
            type: 'ADD_ALERT',
        })

        response = json
    } catch (error) {
        dispatch({
            alert: 'error',
            text: (error as Error).message,
            type: 'ADD_ALERT',
        })
    }

    return response
}
