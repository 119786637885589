import React from 'react';
import {useTranslation} from 'react-i18next';
import Text from '../Inputs/Text';

export default () => {
    const {t} = useTranslation();

    return <div
        style={{display: 'flex', justifyContent: 'space-between'}}>
        <Text
            id='apiKey'
            label={t('apiKey')}
            maxLength={64}
            required
            size={64}
            style={{flexGrow: 1}}
        />
    </div>;
}
