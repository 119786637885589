import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Options} from '../../types'
import AppContext from '../lib/AppContext'
import {getSettings} from '../lib/getSettings'
import {SetLoadingAction, SetOptionsAction} from '../types'
import {Alerts} from './Alerts'
import Form from './Options/Form'

/*window.BX24 = window.BX24 ? window.BX24 : {
    getAuth: () => ({
        access_token: '',
        domain: '',
        expires_in: '',
        member_id: '',
        refresh_token: '',
    }),
    init: fn => fn(),
    callMethod() {
    },
    installFinish() {
    },
    isAdmin: () => true,
    selectUser(callback) {
        const u: BitrixUser = {
            id: '',
            name: '',
            photo: '',
            position: '',
            sub: false,
            sup: false
        };
        callback(u);
    },
    appOption: {
        get: () => '',
        set: () => null,
    }
};*/

export default () => {
    const {t} = useTranslation()
    const {dispatch, state} = useContext(AppContext)
    const [initialOptions, setInitialOptions] = useState(state.options)
    const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {
        window.BX24.installFinish()

        dispatch({
            loading: true,
            type: 'SET_LOADING',
        } as SetLoadingAction)

        window.BX24.init(async () => {
            setIsAdmin(window.BX24.isAdmin())

            const options: Options = {
                ...state.options,
                ...await getSettings({dispatch}),
            }

            dispatch({
                options,
                type: 'SET_OPTIONS',
            } as SetOptionsAction)

            setInitialOptions(options)

            dispatch({
                loading: false,
                type: 'SET_LOADING',
            } as SetLoadingAction)
        })
    }, [])

    return <>
        <h1 style={{textAlign: 'center'}}>
            Sms77.io
        </h1>

        <Alerts/>

        {
            state.loading && <div>
                {t('loading')}
            </div>
        }

        {
            isAdmin
                ? <Form
                    disabled={JSON.stringify(initialOptions) === JSON.stringify(state.options)}
                />
                : t('adminsOnly')
        }

    </>
}
