import React from 'react'
import {useTranslation} from 'react-i18next'
import {CountryChooserProps} from '../../types'
import {countries} from '../countries'

export const CountryChooser = ({onChange, ...props}: CountryChooserProps) => {
    const {t} = useTranslation();

    return <select
        onChange={e => {
            const countryCode = e.currentTarget.value;
            const country = countries.find(c => countryCode === c.code)!;

            onChange(country);
        }}
        {...props}
    >
        <option disabled value=''>{t('selectOption')}</option>

        {
            countries.map((c, i) =>
                <option
                    aria-label={`${t('country')} ${c.name}`}
                    key={i}
                    value={c.code}
                >
                    {c.dial_code} {c.flag}
                </option>)
        }
    </select>;
};