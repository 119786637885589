import React, {PropsWithChildren, useContext, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {cleanNumber, isSharedNumber} from '../../../shared'
import {Country} from '../../../types'
import AppContext from '../../lib/AppContext'
import {Inbound, SetOptionAction} from '../../types'
import Button from '../Buttons/Button'
import YesButton from '../Buttons/YesButton'
import {CountryChooser} from '../CountryChooser'
import ErrorText from '../Inputs/ErrorText'

const InboundCounterSpan: React.FC<PropsWithChildren<{}>> = ({children}) => <span
    style={{
        backgroundColor: 'lightgrey',
        borderRadius: 6,
        padding: 3,
    }}
>{children}</span>

export default () => {
    const {t} = useTranslation()
    const {dispatch, state} = useContext(AppContext)

    const is1stRender = useRef(true)
    const [selected, setSelected] = useState<Inbound[]>([])
    const [inbound, setInbound] = useState('')
    const [error, setError] = useState<string | null>(null)
    const [disabled, setDisabled] = useState(true)
    const [country, setCountry] = useState<Country | null>(null)

    useEffect(() => {
        let err = null

        if (is1stRender.current) {
            is1stRender.current = false
            return
        }

        if (state.options.inbounds.find(o => o.number === inbound)) {
            err = t('numberExists')
        } else if (isSharedNumber(inbound)) {
            err = t('sharedNumbersUnsupported')
        }

        setError(err ? err : null)

        setDisabled(Boolean(err || !inbound.length))
    }, [inbound])

    const setInbounds = (inbounds: Inbound[]): void => {
        dispatch({
            key: 'inbounds',
            type: 'SET_OPTION',
            value: inbounds,
        } as SetOptionAction<'inbounds'>)
    }

    const findSelectedIndex = (i: Inbound): number => selected.findIndex(s => s.number === i.number)

    return <div style={{display: 'grid', gridTemplateColumns: '50% 50%'}}>
        <div>
            <label>
                {t('inboundNumbers.title')}
            </label>

            <p>
                {t('inboundNumbers.intro')}
            </p>
        </div>

        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div>
                <div
                    style={{
                        alignItems: 'center',
                        borderBottom: '1px solid #000',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Button
                        disabled={!state.options.inbounds.length}
                        label={t('toggleAll')}
                        onClick={() => setSelected(selected.length ? [] : state.options.inbounds)}
                        size='tiny'
                        title={t('inboundNumbers.toggleAll')}
                    />

                    <div>
                        <InboundCounterSpan>{selected.length}</InboundCounterSpan>
                        /
                        <InboundCounterSpan>{state.options.inbounds.length}</InboundCounterSpan>
                    </div>

                    <Button
                        disabled={0 === selected.length}
                        label={t('delete')}
                        onClick={() => {
                            setInbounds(state.options.inbounds.filter(() => !selected.includes))

                            setSelected([])
                        }}
                        size='tiny'
                        title={t('inboundNumbers.toggleAll')}
                        variant='danger'
                    />
                </div>

                <ul style={{paddingInlineStart: 0}}>
                    {
                        state.options.inbounds.map((nr: Inbound, i: number) => <li
                            key={i}
                            style={{display: 'flex', justifyContent: 'space-between'}}
                        >
                            <input
                                checked={-1 !== findSelectedIndex(nr)}
                                onChange={e => {
                                    const _selected = [...selected]

                                    e.currentTarget.checked
                                        ? _selected.push(nr)
                                        : _selected.splice(findSelectedIndex(nr), 1)

                                    setSelected(_selected)
                                }}
                                type='checkbox'
                            />

                            {nr.label}
                        </li>)
                    }
                </ul>
            </div>

            <div style={{display: 'flex'}}>
                <CountryChooser
                    onChange={setCountry}
                    value={country ? country.code : ''}
                />

                <input
                    disabled={'' === state.options.apiKey || !country}
                    maxLength={32}
                    onChange={e => setInbound(e.currentTarget.value.replace(' ', ''))}
                    placeholder={t('inboundNumbers.add')}
                    style={{flexGrow: 1}}
                    type='tel'
                    value={inbound}
                />

                <YesButton
                    disabled={disabled}
                    label='Add'
                    onClick={() => {
                        const toInbound = (label: Inbound['label']): Inbound => {
                            if (!country) throw new Error('Country may not null at this point.')

                            return {
                                countryCode: country.dial_code,
                                label: `${country.dial_code}${label}`,
                                number: cleanNumber(label),
                            }
                        }

                        setInbounds([
                            ...state.options.inbounds,
                            toInbound(inbound),
                        ])

                        setCountry(null)

                        setInbound('')
                    }}
                    size='small'
                    title={t('inboundNumbers.add')}
                />
            </div>

            <ErrorText error={error}/>
        </div>
    </div>
}