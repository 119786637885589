import React, {useContext, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import AppContext from '../../lib/AppContext'
import {BitrixUser, CurrentUserResponse, SetOptionAction} from '../../types'

export default () => {
    const {t} = useTranslation()
    const {dispatch, state: {options: {apiKey, responsible}}} = useContext(AppContext)
    const is1stRender = useRef(true)
    const disabled = '' === apiKey

    useEffect(() => {
        if (is1stRender.current) {
            is1stRender.current = false
            return
        }

        if (!responsible) {
            window.BX24.callMethod('user.current', {}, (res: CurrentUserResponse) => {
                const {EMAIL, ID, NAME, PERSONAL_PHOTO, WORK_POSITION} = res.data()

                setResponsible({
                    id: ID,
                    name: ((NAME || '').length ? NAME : EMAIL)!,
                    photo: PERSONAL_PHOTO || '',
                    position: WORK_POSITION || '',
                    sub: false,
                    sup: false,
                })
            })
        }
    }, [responsible])

    const setResponsible = (value: BitrixUser) => {
        dispatch({
            key: 'responsible',
            value,
            type: 'SET_OPTION',
        } as SetOptionAction<'responsible'>)
    }

    const handleClick = () => {
        window.BX24.selectUser(u => responsible?.id !== u.id && setResponsible(u))
    }

    return <>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <label htmlFor='responsible'>
                {t('responsible')}
            </label>

            <p style={{marginBlockEnd: 0, marginBlockStart: 0}}>
                {t('responsibleDesc')}
            </p>
        </div>

        <div style={{display: 'flex'}}>
            <input
                disabled={disabled}
                id='responsible'
                readOnly
                style={{flexGrow: 1}}
                value={responsible ? responsible.name : ''}
            />

            <button
                disabled={disabled}
                onClick={handleClick}
                type='button'
            >
                {t('set')}
            </button>
        </div>
    </>
}
