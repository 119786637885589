import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import constants from '../../server/lib/constants'
import de from './de/index.json'
import en from './en/index.json'

i18n
    .use(initReactI18next) // pass i18n down to react-i18next
    .init({
        resources: {
            de,
            en,
        },
        debug: !constants.isProd,
        defaultNS: 'translation',
        fallbackNS: 'translation',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // react already prevents xss
        },
    })
    .then()

export default i18n