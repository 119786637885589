import React, {CSSProperties} from 'react'
import {ButtonParams} from '../../types'

export default ({label, size, variant, ...attrs}: ButtonParams) => {
    const style: CSSProperties = {
        backgroundColor: 'danger' === variant ? 'red' : 'blue',
        fontWeight: 'bold',
    }

    let xPadding: CSSProperties['paddingLeft']
    let fontSize: CSSProperties['fontSize']
    switch (size) {
        case 'tiny':
            fontSize = 12
            xPadding = 7.5
            break
        case 'small':
            fontSize = 14
            xPadding = 15
            break
    }

    style.paddingLeft = xPadding
    style.paddingRight = xPadding
    style.fontSize = fontSize

    return <button
        style={style}
        type='button'
        {...attrs}
    >
        {label}
    </button>
}