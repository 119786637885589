import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import AppContext from '../../lib/AppContext'
import {postSettings} from '../../lib/postSettings'
import ApiKey from './ApiKey'
import Inbounds from './Inbounds'
import {InboundSmsTrigger} from './InboundSmsTrigger'
import PerformanceTracking from './PerformanceTracking'
import ReinitializeSms from './ReinitializeSms'
import Responsible from './Responsible'

export default ({disabled}: {
    disabled: boolean
}) => {
    const {t} = useTranslation()
    const {dispatch, state} = useContext(AppContext)

    return <>
        <form
            onSubmit={async e => {
                e.preventDefault()

                await postSettings({
                    data: {
                        options: state.options,
                    },
                    dispatch,
                    t,
                })
            }}
        >
            <ApiKey/>

            <hr/>

            <Responsible/>

            <hr/>

            <PerformanceTracking/>

            <InboundSmsTrigger/>

            <Inbounds/>

            <hr/>

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <button disabled={disabled || state.loading} type='submit'>
                    {t('saveOptions')}
                </button>
            </div>
        </form>

        <ReinitializeSms/>
    </>
}
