import {resolve} from 'path'

export const isProd = (process.env.NODE_ENV || 'dev').startsWith('prod')

export const dummyApiKey = 'HeJyJSAvBWDn5RwNfhQGKZI6poCLk7pUXjpxctipYHWGsjoHtWNDI3d4De8gkoVe'

export const express = {
    port: 8081,
    root: resolve('dist'),
}

export const host = isProd
    ? 'https://bitrix24.sms77.dev/'
    : 'https://sms77.eu.ngrok.io/'

export const oauth = {
    clientId: 'app.5fa9131a0e8bd4.61674260',
    clientSecret: 'bD6BaBvnoL2U10PUs4T4yq5R62RR14Hw4wvdozJTlf0s6KIaBB',
}

export const smsCodes = {
    partialSuccess: '101',
    success: '100',
}

export default {
    dummyApiKey,
    express,
    host,
    isProd,
    oauth,
    smsCodes,
}
