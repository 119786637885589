import React, {useContext} from 'react'
import AppContext from '../lib/AppContext'

export type Alert = {
    alert: 'error' | 'info'
    text: string
}

export const Alerts = () => {
    const {dispatch, state: {alerts}} = useContext(AppContext)

    return <>
        {
            alerts.map((alert, index) => <div className={`alert ${alert.alert}`} key={index}>
        <span
            className='close'
            onClick={() => {
                dispatch({
                    index,
                    type: 'REMOVE_ALERT',
                })
            }}
        >
            &times;
        </span>
                {alert.text}
            </div>)
        }
    </>
}