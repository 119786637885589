{
    "translation": {
        "apiKey": "API Key",
        "adminsOnly": "Only administrators are allowed to change settings.",
        "country": "Country",
        "delete": "Delete 🗑",
        "inboundNumbers": {
            "add": "Add new inbound number",
            "deleteAll": "Delete selected inbound number(s)",
            "intro": "By adding inbound numbers we will start listening to inbound SMS and append them to the activity history. A new lead will be created if no contact with the incoming number could be found.",
            "title": "Inbound Numbers",
            "toggleAll": "Toggle all inbound numbers"
        },
        "inboundSmsTrigger": "Add trigger for inbound SMS?",
        "loading": "Loading...",
        "numberExists": "This number already exists.",
        "performanceTracking": "Performance tracking",
        "reinitializedSms": "Reinitialized SMS - try send again.",
        "reinitializeSms": "Reinitialize SMS",
        "reinitializeSmsDescription": "Reinitialize the SMS message service if you have trouble sending SMS.",
        "responsible": "Responsible",
        "responsibleDesc": "This is the employee which gets assigned new incoming messages.",
        "saveOptions": "Save Options",
        "selectOption": "Select an option",
        "set": "Set",
        "settingsUpdated": "Your settings have been updated!",
        "sharedNumbersUnsupported": "Shared numbers are currently not supported.",
        "toggleAll": "Toggle all"
    }
}