import {Dispatch} from 'react'
import {Endpoint} from '../../shared'
import {Options} from '../../types'
import {AddAlertAction} from '../types'
import {requestAPI} from './requestAPI'

export const getSettings =
    async (params: {
        dispatch: Dispatch<AddAlertAction>
    }): Promise<Options | undefined> => {
        return await requestAPI<Options>({
            ...params,
            endpoint: Endpoint.Settings,
            method: 'GET',
        })
    }
