import React from 'react'
import {useTranslation} from 'react-i18next'
import Checkbox from '../Inputs/Checkbox'

export default () => {
    const {t} = useTranslation()

    return <Checkbox
        id='performanceTracking'
        label={t('performanceTracking')}
    />
}