import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {Endpoint} from '../../../shared'
import {Options} from '../../../types'
import AppContext from '../../lib/AppContext'
import {requestAPI} from '../../lib/requestAPI'
import {AddAlertAction} from '../../types'
import Button from '../Buttons/Button'

export default () => {
    const {t} = useTranslation()
    const {dispatch} = useContext(AppContext)

    const handleClick = async () => {
        await requestAPI<Options>({
            dispatch,
            endpoint: Endpoint.ReinitializeSms,
            method: 'POST',
        })

        dispatch({
            alert: 'error',
            text: t('reinitializedSms'),
            type: 'ADD_ALERT',
        } as AddAlertAction)
    }

    return <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Button
            label={t('reinitializeSms')}
            onClick={handleClick}
            variant='danger'
        />

        <p>
            {t('reinitializeSmsDescription')}
        </p>
    </div>
}
