import React, {InputHTMLAttributes, useContext} from 'react'
import {Options} from '../../../types'
import AppContext from '../../lib/AppContext'
import {SetOptionAction} from '../../types'
import ErrorText from './ErrorText'

export type BaseInputParams = InputHTMLAttributes<any> & {
    error?: string | null
    id: keyof Options
    label?: string
    placeholder?: string
    required?: boolean
    size?: number
    type?: HTMLInputElement['type']
}

export default ({error, label, ...attrs}: BaseInputParams) => {
    const {dispatch, state: {options}} = useContext(AppContext)
    const value = options[attrs.id]

    return <>
        {label && <label htmlFor={attrs.id}>
            {label}
        </label>}

        <input
            {...attrs}
            onChange={e => {
                dispatch({
                    key: attrs.id,
                    value: e.currentTarget.value,
                    type: 'SET_OPTION',
                } as SetOptionAction<typeof attrs.id>)
            }}
            value={value as string}
        />

        <ErrorText error={error}/>
    </>
}
